import React from "react";
import ReactDOM from "react-dom/client";

import Connect from "../../components/connect/Connect";
import Training from "../../components/training/Training";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App></App>
    </React.StrictMode>
);

const genUuid = () => {
    return ("" + 1e7 + -1e3 + -1e3 + -8e3 + -1e11).replace(/[018]/g, c => {
        let r = crypto.getRandomValues(new Uint8Array(1))[0] & 0xf;
        return r.toString(16);
    });
}

const uuid = genUuid();

function App() {
    const [step, setStep] = React.useState(0); // todo

    const next = () => {
        setStep(step + 1);
    }

    return <div className="page-single">
        {step === 0 && <Connect uuid={uuid} onNext={next} />}
        {step === 1 && <Training uuid={uuid} />}
    </div>;
}
