`use strict`;

import React from "react";
import { api_log, api_ask } from "./api";
import VideoPlayer from "../videoplayer/VideoPlayer";
import faqQuestions from "./faq.json";

interface Save {
    src: string,
    range: Range[],
    duration: number,
    date: Date,
}

let saves = [] as Save[];

interface TrainingProps {
    uuid: string;
    onNext?: () => void;
}

function Training(props: TrainingProps) {
    const [error, setError] = React.useState("");
    const [accepted, setAccepted] = React.useState(false);
    const [introplayed, setIntroPlayed] = React.useState(false);
    const [inQuestion, setInQuestion] = React.useState(false);
    const [inFAQ, setInFAQ] = React.useState(false); // React.useState(false);
    const [video, setVideo] = React.useState("");
    const firstnameRef = React.useRef(null);
    const lastnameRef = React.useRef(null);
    const phoneRef = React.useRef(null);

    React.useEffect(() => {
        const onKeydown = (evt: KeyboardEvent) => {
            // console.log(`${evt.code} accepted=${accepted} introplayed=${introplayed} video=${video}`);

            switch (evt.code) {
                case "Enter": case "Return":
                    if (!accepted) {
                        setAccepted(true);
                    }
                    break;

                case "Escape":
                    if (accepted && !introplayed) {
                        setIntroPlayed(true);
                        break;
                    }

                    if (accepted && introplayed && video !== "") {
                        setVideo("");
                    }
                    break;
            }
        }

        window.addEventListener('keydown', onKeydown);
        return () => window.removeEventListener('keydown', onKeydown);
    }, [accepted, introplayed, video]);

    React.useEffect(() => {
        document.body.className = "training";

        const handle = window.setInterval(() => {
            const copy = [...saves];
            if (copy.length > 0) {
                saves = [];
                api_log(props.uuid, copy)
                    .then(console.log)
                    .catch(console.error)
                    ;
            }
        }, 5000);

        return () => {
            clearInterval(handle);
        }
    }, []);

    const onAccept = (_evt: React.ClickEvent<HTMLButtonElement>) => {
        setAccepted(true);
    }

    const onSkipIntro = (_evt: React.ClickEvent<HTMLButtonElement>) => {
        setIntroPlayed(true);
    }

    const playVideo = (name: string) => (_evt: React.ClickEvent<HTMLButtonElement>) => {
        setVideo(name);
    }

    const playIntro = (evt: React.ClickEvent<HTMLButtonElement>) => {
        window.scrollTo(0, 0);
        setIntroPlayed(false);
        setVideo("");
    }

    const askQuestion = (evt: React.ClickEvent<HTMLButtonElement>) => {
        setInQuestion(true);
    }

    const showFAQ = (_evt: React.ClickEvent<HTMLButtonElement>) => {
        setInFAQ(true);
    }

    const sendQuestion = (evt: React.ClickEvent<HTMLButtonElement>) => {
        const firstname = firstnameRef.current?.value;
        const lastname = lastnameRef.current?.value;
        const phone = phoneRef.current?.value;

        api_ask(props.uuid, firstname, lastname, phone)
            .then(() => {
                // todo: add acknoledge
                setInQuestion(false);
                setVideo("");
            })
            .catch(() => {
                // todo: add better error message
                setError("La demande n'a pas pu être envoyée");
            })
            ;
    }

    return <>
        <div id="arc">
            <img src="/img/arc-gris.png" alt="" />
        </div>
        <main>
            <div id="logo">
                <img src="/img/logo-ogf-bleu.png" alt="" />
            </div>
            <div id="title">
                <h1>LES SERVICES APRÈS OBSÈQUES</h1>
            </div>
            <div id="leftbar">
                <div></div>
            </div>
            {inFAQ ? (
                <div id="question">
                    <h1>FOIRE AUX QUESTIONS</h1>
                    <div class="accordion">
                        {faqQuestions.map((block, index) => (
                            <div class={(index > 0) ? "accordion-item indent" : "accordion-item"}>
                                {(index > 0) ? (
                                    <>
                                        <input type="checkbox" id={"item-"+index} />
                                        <label for={"item-"+index} class="accordion-title">{block.block}</label>
                                    </>
                                ) : (
                                    <>
                                        <input type="checkbox" id={"item2-"+index} checked />
                                        <label htmlFor={"item2-"+index}></label>
                                    </>
                                )}
                                {block.questions.map(question => (
                                    <div class="accordion-content">
                                        <h2>{question.title}</h2>
                                        {question.texts.map(text => text == "#image" ? (
                                            <div id="prix-services">
                                                <img src="/img/icons/logo-ogf.png" alt="logo-ogf" />
                                                <article id="a-arret">
                                                    <div>
                                                        <h3>ARRET NUMERIQUE</h3>
                                                        <p>Résiliation des comptes numériques du défunt</p>
                                                        <h4>49 &euro; TTC</h4>
                                                    </div>
                                                    <figure><img src="/img/icons/logo-arret.png" alt="logo-arret" /></figure>
                                                </article>
                                                <article id="a-pension">
                                                    <div>
                                                        <h3>PENSION DE REVERSION</h3>
                                                        <p>Construction de la carrière du défunt et enregistrement en ligne (sous 24h)</p>
                                                        <h4>360 &euro; TTC</h4>
                                                    </div>
                                                    <figure><img src="/img/icons/logo-pension.png" alt="logo-pension" /></figure>
                                                </article>
                                                <article id="a-recherche">
                                                    <div>
                                                        <h3>RECHERCHE DE FONDS</h3>
                                                        <p>Investigation et la récupération de capitaux. Échange découverte et diagnostic.</p>
                                                        <p>Rémunération au succès</p>
                                                        <h4>3,6% sur les assurances vies</h4>
                                                        <h4>9% sur les fonds perdus</h4>
                                                    </div>
                                                    <figure><img src="/img/icons/logo-recherche.png" alt="logo-recherche" /></figure>
                                                </article>
                                                <article id="a-courriers">
                                                    <div>
                                                        <h3>COURRIERS ET FORMALITÉS (SFAO)</h3>
                                                        <p>Résiliation des abonnements souscrits par le défunt. Changement de nom.</p>
                                                        <h4>299 &euro; TTC (agence)</h4>
                                                    </div>
                                                    <figure><img src="/img/icons/logo-formalite.png" alt="logo-formalite" /></figure>
                                                </article>
                                                <article id="a-carte">
                                                    <div>
                                                        <h3>CARTE GRISE</h3>
                                                        <p>Mise en conformité de la carte grise. Cession vers un tiers.</p>
                                                        <h4>90 &euro; TTC (agence)</h4>
                                                    </div>
                                                    <figure><img src="/img/icons/logo-carte.png" alt="logo-carte" /></figure>
                                                </article>
                                            </div>
                                        ) : (
                                            <p>{text}</p>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                        <div className="right">
                            <button onClick={() => setInFAQ(false)}>RETOUR</button>
                        </div>
                </div>
            ) : inQuestion ? (
                <div id="question">
                    <h1>UNE QUESTION?</h1>
                    <h2>POUR ÊTRE CONTACTÉ RAPIDEMENT :</h2>
                    <div><input type="text" placeholder="Nom" ref={firstnameRef} id="firstname" name="firstname" /></div>
                    <div><input type="text" placeholder="Prénom" ref={lastnameRef} id="lastname" name="lastname" /></div>
                    <div><input type="text" placeholder="+33" ref={phoneRef} id="phone" name="phone" /></div>
                    <div className="center">
                        <button onClick={() => setInQuestion(false)}>ANNULER</button>&nbsp;
                        <button onClick={sendQuestion}>ENVOYER</button>
                    </div>
                </div>
            ) : (video == "") ? (
                <>
                    <div id="icons">
                        <div onClick={playVideo("pensions")}>
                            <img src="/img/svg/pensions.svg" alt="" />
                            <div>
                                <div>Pension de réversion</div>
                                <div></div>
                            </div>
                        </div>
                        <div onClick={playVideo("recherche")}>
                            <img src="/img/svg/recherche.svg" alt="" />
                            <div>
                                <div>Recherche de fonds</div>
                                <div></div>
                            </div>
                        </div>
                        <div onClick={playVideo("carte")}>
                            <img src="/img/svg/carte-grise.svg" alt="" />
                            <div>
                                <div>Carte grise</div>
                                <div></div>
                            </div>
                        </div>
                        <div onClick={playVideo("arret")}>
                            <img src="/img/svg/arret-numerique.svg" alt="" />
                            <div>
                                <div>Arrêt numérique</div>
                                <div></div>
                            </div>
                        </div>
                        <div onClick={playVideo("courriers")}>
                            <img src="/img/svg/courriers.svg" alt="" />
                            <div>
                                <div>Courriers et formalités</div>
                                <div>(SFAO)</div>
                            </div>
                        </div>
                    </div>
                    <div id="player"></div>
                    <div id="buttons">
                        <button title="faq" type="button" onClick={showFAQ}>FOIRE AUX QUESTIONS</button>
                        <button title="questions" type="button" onClick={askQuestion}>J'AI UNE QUESTION</button>
                    </div>
                </>
            ) : (
                <>
                    <div id="icons-inline">
                        <div className={video == "pensions" ? "selected" : ""} onClick={playVideo("pensions")}>
                            <img className="black" src="/img/svg/pensions.svg" alt="" />
                            <img className="white" src="/img/svg/pensions-b.svg" alt="" />
                            <div>
                                <div>Pension de réversion</div>
                                <div>&#8203;</div>
                            </div>
                        </div>
                        <div className={video == "recherche" ? "selected" : ""} onClick={playVideo("recherche")}>
                            <img className="black" src="/img/svg/recherche.svg" alt="" />
                            <img className="white" src="/img/svg/recherche-b.svg" alt="" />
                            <div>
                                <div>Recherche de fonds</div>
                                <div>&#8203;</div>
                            </div>
                        </div>
                        <div className={video == "carte" ? "selected" : ""} onClick={playVideo("carte")}>
                            <img className="black" src="/img/svg/carte-grise.svg" alt="" />
                            <img className="white" src="/img/svg/carte-grise-b.svg" alt="" />
                            <div>
                                <div>Carte grise</div>
                                <div>&#8203;</div>
                            </div>
                        </div>
                        <div className={video == "arret" ? "selected" : ""} onClick={playVideo("arret")}>
                            <img className="black" src="/img/svg/arret-numerique.svg" alt="" />
                            <img className="white" src="/img/svg/arret-numerique-b.svg" alt="" />
                            <div>
                                <div>Arrêt numérique</div>
                                <div>&#8203;</div>
                            </div>
                        </div>
                        <div className={video == "courriers" ? "selected" : ""} onClick={playVideo("courriers")}>
                            <img className="black" src="/img/svg/courriers.svg" alt="" />
                            <img className="white" src="/img/svg/courriers-b.svg" alt="" />
                            <div>
                                <div>Courriers et formalités</div>
                                <div>(SFAO)</div>
                            </div>
                        </div>
                    </div>
                    <VideoPlayer notify={(e: any) => saves.push(e)} loop={true} autoplay={true} src={"/videos/" + video + ".mp4"} />
                    <div id="buttons">
                        <button title="introduction" type="button" onClick={playIntro}>RETOUR À L'INTRODUCTION</button>
                        <button title="faq" type="button" onClick={showFAQ}>FOIRE AUX QUESTIONS</button>
                        <button title="questions" type="button" onClick={askQuestion}>J'AI UNE QUESTION</button>
                    </div>
                </>
            )}
        </main>
        {!accepted ? (
            <div id="frame">
                <div id="popup">
                    <div>
                        <b>OGF</b> a mis en place le <b>Service Formalités Après Obsèques (SFAO)</b>
                    </div>
                    <div>
                        Aujourd'hui, <b>OGF</b> complète son offre de services avec l'ajout de 4 nouveau services pour désormais s'appeler le "<b>Service Après Obsèques</b>".
                    </div>
                    <div id="icons-intro">
                        <div>
                            <img src="/img/svg/pensions.svg" alt="" />
                            <div>Le service pension de réversion</div>
                        </div>
                        <div>
                            <img src="/img/svg/recherche.svg" alt="" />
                            <div>Le service recherche de fonds</div>
                        </div>
                        <div>
                            <img src="/img/svg/carte-grise.svg" alt="" />
                            <div>Le service carte grise</div>
                        </div>
                        <div>
                            <img src="/img/svg/arret-numerique.svg" alt="" />
                            <div>Le service arrêt numérique</div>
                        </div>
                        <div>
                            <img src="/img/svg/courriers.svg" alt="" />
                            <div>Le service courriers et formalités (SFAO)</div>
                        </div>
                    </div>
                    <div>En cliquant sur le bouton "Accepter" vous allez découvrir les vidéos de formation.</div>
                    <div>Les familles ont besoin de vous pour accéder à ces différents services.</div>
                    <div className="center"><button type="button" onClick={onAccept}>ACCEPTER</button></div>
                </div>
            </div>
        ) : !introplayed && (
            <div id="frame">
                <div id="intro">
                    <VideoPlayer notify={(e: any) => saves.push(e)} loop={true} autoplay={true} src={"/videos/intro.mp4"} />
                    <div className="center"><button type="button" onClick={onSkipIntro}>PASSER LA VIDEO</button></div>
                </div>
            </div>
        )}
        {error && (
            <div id="alert">
                <div>{error}</div>
                <button onClick={() => setError("")}>X</button>
            </div>
        )}
    </>
}

export default Training;
