`use strict`;

import React from "react";
import { api_connect } from "./api";

interface ConnectProps {
    uuid: string;
    onNext?: () => void;
}

function Connect(props: ConnectProps) {
    const [error, setError] = React.useState("");
    const [code, setCode] = React.useState("");

    const onConnect = (evt: React.ClickEvent<HTMLButtonElement>) => {
        evt.preventDefault();

        api_connect(props.uuid, code)
            .then((_data) => {
                if (!props.onNext) {
                    window.location.href = "/training"
                    return;
                }
                props.onNext();
                return;
            })
            .catch((_data) => {
                setError(`Le code d'activité "${code}" est inconnu`);
            })
            ;
    }

    React.useEffect(() => {
        document.body.className = "connect";
    }, []);

    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const target = evt.currentTarget;
        const value = target?.value;
        // console.log(`set code to "${value}"`);
        const tmp = parseInt(value, 10);
        if (isNaN(tmp)) {
            setCode("");
            return;
        }
        setCode(value);
    }

    return <>
        <div id="arc">
            <img src="/img/arc-beige.png" alt="" />
        </div>
        {error && (
            <div id="alert">
                <div>{error}</div>
                <button onClick={() => setError("")}>X</button>
            </div>
        )}
        <main>
            <div id="logo">
                <img src="/img/logo-ogf-blanc.png" alt="" />
            </div>
            <div id="title">
                <h1>LES SERVICES APRÈS OBSÈQUES</h1>
            </div>
            <div id="leftbar">
                <div className="bar"></div>
            </div>
            <form onSubmit={onConnect} id="login">
                <h2>CODE D'ACTIVITÉ</h2>
                <div id="line"><input onChange={onChange} type="text" /></div>
                <div id="submit"><button disabled={code === ""} type="submit">SE CONNECTER</button></div>
            </form>
        </main>
    </>
}

export default Connect;
