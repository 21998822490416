export async function api_log(uuid: string, data: any) {
    return new Promise((resolve, reject) => {
        const headers = new Headers;
        headers.append("Content-Type", "application/json");

        const myInit: RequestInit = {
            method: 'POST',
            mode: 'cors',
            headers: headers,
            cache: 'no-store',
            body: JSON.stringify(data)
        };

        const myRequest = new Request(`/api/v1/training/${uuid}`, myInit);
        fetch(myRequest)
            .then(async (response) => {
                if (response.status != 200) {
                    console.error(`api_connect: server returned ${response.status}`);
                    reject(response.status);
                    return
                }
                response.json()
                    .then((data: any) => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(`api_connect: json caught error ${error}`);
                        reject(-2);
                    })
            })
            .catch(error => {
                console.error(`api_connect: caught error ${error}`);
                reject(-1);
            })
            ;
    });
}

export const api_ask = (uuid: string, firstname: string, lastname: string, phone: string) => {
    return new Promise((resolve, reject) => {
        const headers = new Headers;
        headers.append("Content-Type", "application/json");

        const myInit: RequestInit = {
            method: 'POST',
            mode: 'cors',
            headers: headers,
            cache: 'no-store',
            body: JSON.stringify({
                firstname,
                lastname,
                phone,
            })
        };

        const myRequest = new Request(`/api/v1/question/${uuid}`, myInit);
        fetch(myRequest)
            .then(async (response) => {
                if (response.status != 200) {
                    console.error(`api_connect: server returned ${response.status}`);
                    reject(response.status);
                    return
                }
                response.json()
                    .then((data: any) => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(`api_connect: json caught error ${error}`);
                        reject(-2);
                    })
            })
            .catch(error => {
                console.error(`api_connect: caught error ${error}`);
                reject(-1);
            })
            ;
    });
}