[
    {
        "block": "#",
        "questions": [
            {
                "title": "À quoi sert le Service Après Obsèques ?",
                "texts": [
                    "Le Service Après Obsèques c'est une nouvelle offre personnalisée au service des familles endeuillées, qui comprend cinq prestations sur-mesure."
                ]
            },
            {
                "title": "Les Services Après Obsèques se facturent-ils dans mon devis ?",
                "texts": [
                    "Uniquement le service Courriers et Formalités (SFAO), le reste des quatre prestations sont facturées entre la famille et le Service Après Obsèques."
                ]
            },
            {
                "title": "Quel est le numéro utile ?",
                "texts": [
                    "Le numéro historique du SFAO, le 0800 11 10 80."
                ]
            },
            {
                "title": "Je ne comprends pas les services qui dois-je appeler ?",
                "texts": [
                    "Pour tous les rendez-vous SFAO historique et Service Après Obsèques,, prenez rendez-vous au numéro 0800 11 10 80."
                ]
            },
            {
                "title": "En cas de SAV avec une famille, quelle est la procédure ?",
                "texts": [
                    "Une prise de contact avec le service de la réservation SFAO pour remonter l'information."
                ]
            }
        ]
    },
    {
        "block": "Recherche de fonds",
        "questions": [
            {
                "title": "Qu'est-ce que la recherche de fonds ?",
                "texts": [
                    "Lors d'un décès une partie de l'argent revenant aux familles peut passer inaperçue aux yeux des ayants droit. Soit parce que ces fonds ne sont pas connus de la famille, soit parce que ces fonds n'entrent pas dans la succession. Nos experts mobilisent leurs compétences pour récupérer ces capitaux dus aux familles. Ce service est sans frais et sans engagement."
                ]
            },
            {
                "title": "Quelle est la différence avec le service SFAO historique ?",
                "texts": [
                    "Plusieurs différences avec le service SFAO : un accompagnement à domicile ou en ligne, une analyse et recherche des droits sous mandat, une relance téléphonique et un contact auprès des organismes et un suivi et déblocage des droits."
                ]
            },
            {
                "title": "Quel est le délai de prise en charge ?",
                "texts": [
                    "Pas de limite de temps de prise en charge pour tous types de décès."
                ]
            }
        ]       
    },
    {
        "block": "Pension de réversion",
        "questions": [
            {
                "title": "Qu'est-ce que la pension de réversion ?",
                "texts": [
                    "La pension de réversion c'est une part de la retraite du défunt qui revient à la conjointe ou au conjoint survivant. (marié, divorcé, + 55 ans)"
                ]
            },
            {
                "title": "Quelle est la différence avec le service SFAO historique ?",
                "texts": [
                    "Enregistrement de la pension de réversion sous 24 heures"
                ]
            },
            {
                "title": "Quel est le délai de prise en charge ?",
                "texts": [
                    "L'enregistrement digital permet de réduire par trois le temps du reversement de la première rente."
                ]
            }
        ]       
    },
    {
        "block": "Carte grise",
        "questions": [
            {
                "title": "Qu'est-ce que la mise en conformité de la carte grise ?",
                "texts": [
                    "Nos équipes assurent cette démarche administrative en ligne auprès des services de l'État. Cela permet d'être en règle en cas de vente du véhicule ou de reprise du véhicule par le conjoint survivant."
                ]
            },
            {
                "title": "Quel est le délai de prise en charge ?",
                "texts": [
                    "Le délai de prise en charge est en moyenne de deux mois."
                ]
            }
        ]       
    },
    {
        "block": "Arrêt numérique",
        "questions": [
            {
                "title": "Qu'est-ce que l'arrêt numérique ?",
                "texts": [
                    "Grâce à l'adresse mail du défunt, nos experts procèdent à la résiliation des comptes numériques afin de mettre fin aux notifications inappropriées et aux rappels douloureux de dates événements."
                ]
            },
            {
                "title": "Quel est le délai de prise en charge ?",
                "texts": [
                    "Nous assurons une résiliation sur la première année."
                ]
            },
            {
                "title": "Est-ce que certains comptes numériques peuvent être mis en commémoration ?",
                "texts": [
                    "Oui, à la demande de la famille."
                ]
            }
        ]       
    },
    {
        "block": "Courriers et formalités (SFAO)",
        "questions": [
            {
                "title": "Qu'est-ce que le service courriers et formalités (SFAO) ?",
                "texts01": [
                    "Le Service Formalités après Obsèques est un service de résiliation des contrats et abonnements au nom du défunt, effectué grâce à un certain nombre de courriers déclaratifs."
                ],
                "texts": [
                    "Le Service Formalités après Obsèques est un service qui effectue un diagnostic personnalisé des besoins, qui est en charge de la défense des intérêts financiers et des droits des familles afin qu'ils puissent les faire valoir. Ils effectuent avec la famille, en direct, les courriers déclaratifs."
                ]
            },
            {
                "title": "Quel est le délai de prise en charge ?",
                "texts": [
                    "Assistance pendant 3 mois par téléphone (uniquement pour le service courriers et formalités (SFAO historique), les autres services n'ont pas de limite de temps)."
                ]
            },
            {
                "title": "Quel est le prix des Services Après Obsèques ?",
                "texts": [
                    "#image"
                ]
            },
            {
                "title": "Le rendez-vous à domicile est-il payant ?",
                "texts": [
                    "Le rendez-vous à domicile est intégré dans les prestations du service hors SFAO. (En fonction des besoins de la famille)."
                ]
            },
            {
                "title": "J'ai un besoin de formation, où dois-je m'adresser ?",
                "texts": [
                    "Faire une demande sur l'application « j'ai une question ». OU Remonter la demande auprès de votre Directeur de Territoire"
                ]
            },
            {
                "title": "Puis-je prendre contact avec le chargé de relation famille Service Après Obsèques lors d'un futur rendez-vous à domicile ?",
                "texts": [
                    "Oui, s'il y a besoin d'apporter des informations complémentaires."
                ]
            }
        ]       
    }
]